import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

interface LocationSpotSelectionProps {
    floorplanUrl: string;
    maxSpots: number;
    bookedSpots: string[];
    onClose: () => void;
    onSave: (selectedSpot: string) => void;
}

const LocationSpotSelection: React.FC<LocationSpotSelectionProps> = ({
    floorplanUrl,
    maxSpots,
    bookedSpots,
    onClose,
    onSave
}) => {
    const [selectedSpot, setSelectedSpot] = useState<string | null>(null);

    const handleSpotClick = (spot: string) => {
        setSelectedSpot(spot);
    };

    const handleSave = () => {
        if (selectedSpot) {
            onSave(selectedSpot);
        }
    };

    return (
        <>
            <div className="flex-vertical gap-xs">
                <div className="top-info-buttons">
                    <button className="button-s secondary square" onClick={onClose}>
                        <CloseIcon className="icon-l" />
                    </button>
                </div>
                <div className="flex-vertical">
                    <h2>Hey coach,</h2>
                    <h1>select a spot!</h1>
                </div>
            </div>
            <img src={floorplanUrl} alt="Floorplan" className="full-width" />
            <div className="spots-container">
                {Array.from({ length: maxSpots }, (_, index) => {
                    const prefix = String.fromCharCode(65 + (index % 6));
                    const number = Math.floor(index / 6) + 1;
                    const spot = `${prefix}${number}`;
                    const isBooked = bookedSpots.includes(spot);
                    const isSelected = selectedSpot === spot;

                    return (
                        <button
                            key={spot}
                            className={`button-circle ${isSelected ? 'active' : ''}`}
                            onClick={() => !isBooked && handleSpotClick(spot)}
                            disabled={isBooked}
                        >
                            {spot}
                        </button>
                    );
                })}
            </div>
            <button className="button-medium special" onClick={handleSave}>
                Save
            </button>
        </>
    );
};

export default LocationSpotSelection;
