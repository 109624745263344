import React from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { calculateWeightDifference } from './utils/weightUtils';

interface Exercise {
  exerciseId: string;
  exerciseName: string;
  weight: number;
  equipment: "Leg Press" | "Cable" | "Dumbbell" | "Barbell" | "Smith Machine";
}

interface Booking {
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string | null;
  has_attended: boolean;
  spot: string | null;
  id: string;
  customer_id: string;
}

interface CustomerDetailViewProps {
  selectedBooking: Booking;
  allCustomerWeights: { [bookingId: string]: Exercise[] };
  toggleDetailView: (booking: Booking | null) => void;
  handleCheckIn: () => void;
  adjustWeight: (exerciseId: string, adjustment: number) => void;
  showButtonRow: boolean;
  onSpotButtonClick: () => void;
}

const CustomerDetailView: React.FC<CustomerDetailViewProps> = ({
  selectedBooking,
  allCustomerWeights,
  toggleDetailView,
  handleCheckIn,
  adjustWeight,
  showButtonRow,
  onSpotButtonClick
}) => {
  return (
    <>
      <div className="flex-vertical gap-xs">
        <button className="button-s secondary square" onClick={() => toggleDetailView(null)}>
          <ArrowBackIcon className="icon-m" />
        </button>
        <div className="flex-vertical centered gap-s">
          {selectedBooking.customer_profile_image_url ? (
            <img
              src={selectedBooking.customer_profile_image_url}
              alt="Profile"
              className="profile-avatar-xl"
            />
          ) : (
            <div className="profile-avatar-xl">
              {selectedBooking.customer_first_name.charAt(0)}
              {selectedBooking.customer_last_name.charAt(0)}
            </div>
          )}
          <h1>
            {selectedBooking.customer_first_name} {selectedBooking.customer_last_name.slice(0, 2)}.
          </h1>
        </div>
      </div>
      {showButtonRow && (
        <div className="flex-horizontal space-around">
          <button
            className={`button-l ${selectedBooking.has_attended ? "active" : ""}`}
            onClick={handleCheckIn}
          >
            {selectedBooking.has_attended ? (
              <>
                <CheckIcon className="icon-m" />
                Checked In
              </>
            ) : (
              "Check In"
            )}
          </button>
          <button
            className={`button-l ${selectedBooking.spot ? "active" : ""}`}
            onClick={onSpotButtonClick}
          >
            {selectedBooking.spot ? `Spot ${selectedBooking.spot}` : "Pick Spot"}
          </button>
        </div>
      )}
      <div className="exercise-list">
        {selectedBooking && allCustomerWeights[selectedBooking.id] ? (
          allCustomerWeights[selectedBooking.id].map((exercise) => {
            const diffPlus = calculateWeightDifference(exercise.equipment, exercise.weight, 1);
            const diffMinus = calculateWeightDifference(exercise.equipment, exercise.weight, -1);
            console.log("Equipment:", exercise.equipment)
            return (
              <div key={exercise.exerciseId} className="flex-horizontal space-between">
                <p className='exercise-name'>{exercise.exerciseName}</p>
                <div className="weight-element">
                  {diffMinus !== null && (
                    <button className="button-s" onClick={() => adjustWeight(exercise.exerciseId, -1)}>
                      -{Math.abs(diffMinus)} kg
                    </button>
                  )}
                  <span className="weight-value">{exercise.weight} kg</span>
                  {diffPlus !== null && (
                    <button className="button-s" onClick={() => adjustWeight(exercise.exerciseId, 1)}>
                      +{diffPlus} kg
                    </button>
                  )}
                </div>
              </div>
            );
          })
        ) : null}
      </div>
    </>
  );
};

export default CustomerDetailView;
