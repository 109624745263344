import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig';
import "./WorkoutWeightDisplay.css";
import "./WorkoutPlayer.css";

interface Exercise {
    name: string;
    reps: number;
    video_url: string;
    exercise_number: number;
    set_type: string;
    exercise_id: string;
    workout_exercise_id: string;
}

interface SpotGroup {
    spot: string;
    customer: {
        firstName: string;
        lastName: string;
    } | null;
    exercises: {
        exerciseId: string;
        weight: number;
    }[];
}

interface WeightDisplaySequence {
    intervalType: string;
    duration: number;
    showWeights: boolean;
    displayGroups: string[];
}

function WorkoutWeightDisplay() {
    const { locationId, displayNumber } = useParams<{ locationId: string, displayNumber: string }>();
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [spotGroups, setSpotGroups] = useState<Record<string, SpotGroup>>({});
    const [error, setError] = useState<string | null>(null);
    const [weightDisplaySequence, setWeightDisplaySequence] = useState<WeightDisplaySequence[]>([]);
    const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [workoutId, setWorkoutId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    // WebSocket Connection
    const [ws, setWs] = useState<WebSocket | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Define fetchData function
    const fetchData = async () => {

        if (workoutId) {
            try {
                const response = await api.get(`/workouts/${workoutId}/weights/${displayNumber}`);
                setExercises(response.data.exercisesToDisplay);
                setSpotGroups(response.data.spotGroups);
                setWeightDisplaySequence(response.data.weightDisplaySequence);
                setLoading(false);
            } catch (error) {
                const err = error as { response?: { data?: { error: string } } };
                setError(err.response?.data?.error || "An error occurred");
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const connectWebSocket = () => {
            const wsUrl = process.env.NODE_ENV === 'production'
                ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
                : "ws://localhost:5000";

            const newWs = new WebSocket(wsUrl);

            newWs.onopen = () => {
                console.log("WorkoutWeightDisplay connected to WebSocket server");
                newWs.send(JSON.stringify({ type: "join", locationId }));
            };

            newWs.onmessage = (message) => {
                console.log("WebSocket message received:", message.data);
                const data = JSON.parse(message.data);
                if (data.type === "timerUpdate" || data.type === "loadTimer") {
                    setWorkoutId(data.workoutId);
                    setCurrentIntervalIndex(data.timerState.intervalIndex);
                    setTimeLeft(data.timerState.timeLeft);
                    setIsRunning(data.timerState.isRunning);
                }
                if (data.type === "bookingUpdate" && workoutId === data.workoutId) {
                    fetchData(); // Re-fetch data on booking update
                }
            };

            newWs.onclose = () => {
                console.log("WebSocket connection closed. Attempting to reconnect...");
                reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
            };

            newWs.onerror = (error) => {
                console.error("WebSocket error:", error);
                newWs.close();
            };

            setWs(newWs);
        };

        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [locationId]);

    useEffect(() => {
        fetchData();
    }, [workoutId, displayNumber]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (isRunning && weightDisplaySequence.length > 0) {
            intervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft > 1) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(intervalId);
                        if (currentIntervalIndex < weightDisplaySequence.length - 1) {
                            const newIntervalIndex = currentIntervalIndex + 1;
                            const newTimeLeft = weightDisplaySequence[newIntervalIndex].duration;

                            setCurrentIntervalIndex(newIntervalIndex);
                            setTimeLeft(newTimeLeft);
                        } else {
                            setIsRunning(false);
                        }
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isRunning, currentIntervalIndex, weightDisplaySequence]);

    const circleCircumference = 2 * Math.PI * 230; // Adjusted for smaller circle

    const strokeDashArray =
        weightDisplaySequence.length > 0 && timeLeft !== null
            ? `${((weightDisplaySequence[currentIntervalIndex].duration - timeLeft) /
                weightDisplaySequence[currentIntervalIndex].duration) *
            circleCircumference
            } ${circleCircumference}`
            : `0 ${circleCircumference}`;

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    };

    // Function to format reps
    const formatReps = (reps: number) => {
        switch (reps) {
            case 1:
                return "1";
            case 4:
                return "4";
            case 6:
                return "6";
            case 8:
                return "8";
            case 12:
                return "12";
            default:
                return reps; // If no match, display the original reps value
        }
    };

    const currentInterval = weightDisplaySequence[currentIntervalIndex];
    const currentIntervalType = currentInterval?.intervalType
        ?.replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    const exercisesToDisplay = exercises.filter(exercise => exercise.set_type === currentIntervalType);
    const displayText = currentIntervalType?.includes("Main")
        ? "Exercise"
        : currentIntervalType?.includes("Change")
            ? "Long Break"
            : currentIntervalType;

    // Sort exercises by exercise number
    exercisesToDisplay.sort((a, b) => a.exercise_number - b.exercise_number);

    // Function to get stroke color based on interval type
    const getStrokeColorForInterval = (currentIntervalType: string) => {
        if (currentIntervalType?.includes("Main") || currentIntervalType?.includes("Challenge")) {
            return "var(--color-neon-blue)";
        } else if (
            currentIntervalType?.includes("Break") ||
            currentIntervalType?.includes("Switch") ||
            currentIntervalType?.includes("Stretching") ||
            currentIntervalType?.includes("Breathing") ||
            currentIntervalType?.includes("Station")
        ) {
            return "var(--color-grey)";
        } else if (currentIntervalType?.includes("Warmup")) {
            return "var(--color-yellow)";
        } else {
            return "var(--color-cream)";
        }
    };

    const strokeColor = getStrokeColorForInterval(currentIntervalType);

    return (
        <div className="workout-weight-display">
            <svg
                className="lifted-logo"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 249 44"
                fill="currentColor" // This will inherit the color from CSS
            >
                <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
                <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
                <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
                <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
                <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
                <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
            </svg>
            {loading ? (
                <div className="loading-message">Loading workout...</div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : (
                <>
                    <div className="weight-timer-circle" style={{ position: 'absolute', top: '64px', right: '64px' }}>
                        <svg width="500" height="500" viewBox="0 0 500 500">
                            <circle
                                cx="250"
                                cy="250"
                                r="240"
                                fill="none"
                                stroke="var(--color-dark-blue)"
                                strokeWidth="10"
                            />
                            <circle
                                cx="250"
                                cy="250"
                                r="190"
                                fill="none"
                                stroke="var(--color-dark-blue)"
                                strokeWidth="10"
                            />
                            <circle
                                className="weight-timer-circle-progress"
                                cx="250"
                                cy="250"
                                r="215"
                                fill="none"
                                stroke={strokeColor}
                                strokeWidth="40"
                                strokeLinecap="square"
                                style={{
                                    strokeDasharray: strokeDashArray,
                                    transform: "rotate(270deg)",
                                    transformOrigin: "center",
                                }}
                            />
                        </svg>
                        <div className="weight-timer-text">
                            <div className="weight-description-text">
                                {displayText}
                            </div>
                            <div className="weight-time-left">
                                {formatTime(timeLeft)}
                            </div>
                        </div>
                    </div>
                    <div className="exercise-customer-container">
                        {exercises[0] && (

                            <div className="exercise-info">
                                <p>{exercises[0].name}</p>
                                <div className="exercise-video-box">
                                    <div className="exercise-video">
                                        <video src={exercises[0].video_url} autoPlay loop muted />
                                    </div>
                                </div>
                                <p>{formatReps(exercises[0].reps)} Reps</p>
                            </div>

                        )}
                        <div className="customer-section">
                            <table>
                                <tbody>
                                    {Object.values(spotGroups)
                                        .filter(spotGroup => currentInterval.showWeights ?
                                            currentInterval.displayGroups.includes(spotGroup.spot.charAt(0)) && spotGroup.customer :
                                            currentInterval.displayGroups.includes(spotGroup.spot.charAt(0)))
                                        .map((spotGroup, index) => (
                                            <tr key={index}>
                                                {currentInterval.showWeights ? (
                                                    <>
                                                        <td className="small-column">{spotGroup.exercises[0]?.weight || ''} kg</td>
                                                        <td className="name-column" style={{ textAlign: 'center' }}>{spotGroup.customer ? `${spotGroup.customer.firstName} ${spotGroup.customer.lastName.slice(0, 2)}.` : ''}</td>
                                                        <td className="small-column">{spotGroup.exercises[1]?.weight || ''} kg</td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="small-column">{spotGroup.spot}</td>
                                                        <td className="name-column">{spotGroup.customer ? `${spotGroup.customer.firstName} ${spotGroup.customer.lastName.slice(0, 2)}.` : ''}</td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        {exercises[1] && (
                            <div className="exercise-info">
                                <p>{exercises[1].name}</p>
                                <div className="exercise-video-box">
                                    <div className="exercise-video">
                                        <video src={exercises[1].video_url} autoPlay loop muted />
                                    </div>
                                </div>
                                <p>{formatReps(exercises[1].reps)} Reps</p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default WorkoutWeightDisplay;
