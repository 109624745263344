import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig'; // Import axios for API requests
import "./WorkoutPlayer.css"; // Import the new CSS file
import moment from "moment-timezone"; // Import moment-timezone

interface TimerData {
  intervals: { duration: number; intervalType: string, exercise_number: number; }[];
  name: string;
  total_duration: number;
}

interface WorkoutData {
  timer_id: number;
  start_datetime: string;
  coach_first_name: string;
  playlists: Record<string, string>;
}

interface ExerciseData {
  exercise_id: number;
  exercise_number: number;
  name: string;
  reps: number;
  set_type: string;
}

interface Milestone {
  firstTimers: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[];
  otherMilestones: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[];
  [key: string]: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[]; // Add this line

}

interface PersonalBest {
  customer_id: number;
  first_name: string;
  last_name: string;
  improvement_count: number;
}

function WorkoutPlayer() {
  const { locationId } = useParams<{ locationId: string }>();
  const [workoutId, setWorkoutId] = useState<number | null>(null); // Get workoutId from URL params
  const [timerData, setTimerData] = useState<TimerData | null>(null);
  const [workoutData, setWorkoutData] = useState<WorkoutData | null>(null); // State to hold workout data
  const [workoutExercisesData, setWorkoutExercisesData] = useState<ExerciseData[] | null>(null); // State to hold workout data
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false); // Start the timer immediately
  const [isLoading, setIsLoading] = useState(true);
  const [milestones, setMilestones] = useState<Milestone | null>(null);
  const [personalBests, setPersonalBests] = useState<PersonalBest[] | null>(null);

  // Add sound declarations
  const soundGo = new Audio("/sounds/Sound_Go.mp3");
  const soundSwitch = new Audio("/sounds/Sound_Switch.mp3");
  const soundLongBreak = new Audio("/sounds/Sound_Long_Break.mp3");
  const soundWarmUp = new Audio("/sounds/Sound_Warm_Up.mp3");
  const soundWorkoutComplete = new Audio("/sounds/Sound_Workout_Complete.mp3");
  const soundWorkoutStart = new Audio("/sounds/Sound_Workout_Start.mp3");
  const soundGetReady = new Audio("/sounds/Sound_Get_Ready.mp3");
  const sound20 = new Audio("/sounds/Sound_20.mp3");
  const sound10 = new Audio("/sounds/Sound_10.mp3");
  const sound3 = new Audio("/sounds/Sound_3.mp3");
  const sound2 = new Audio("/sounds/Sound_2.mp3");
  const sound1 = new Audio("/sounds/Sound_1.mp3");

  // State variables to manage display transitions and progress bars
  const [displayState, setDisplayState] = useState("timer"); // timer, firstTimers, milestones, personalBests
  const [progressBarValue, setProgressBarValue] = useState(0); // 0-100
  const hasMilestones =
    milestones &&
    Object.keys(milestones).filter((key) => key !== "firstTimers").length > 0;
  const hasPersonalBests = personalBests && personalBests.length > 0;

  // Progress circle design elements
  const circleCircumference = 2 * Math.PI * 551; // Updated radius to match SVG

  const strokeDashArray =
    timerData?.intervals && timeLeft !== null
      ? `${((timerData.intervals[currentIntervalIndex].duration - timeLeft) /
        timerData.intervals[currentIntervalIndex].duration) *
      circleCircumference
      } ${circleCircumference}`
      : `0 ${circleCircumference}`;

  useEffect(() => {
    if (timerData && timeLeft === 0 && currentIntervalIndex === 0) {
      setTimeLeft(timerData?.intervals[0].duration);
    }
  }, [timerData]);

  // CODE TO LOAD WORKOUT INFORMATION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // 1. Fetch workout data
        const workoutResponse = await api.get<WorkoutData>(`/workouts/${workoutId}`);
        setWorkoutData(workoutResponse.data);

        let exercisesData: ExerciseData[] = [];
        try {
          const workoutExercisesResponse = await api.get<ExerciseData[]>(`/workout_exercises?workout_id=${workoutId}`);
          exercisesData = workoutExercisesResponse.data;

          if (exercisesData.length > 0) {
            const timerId = workoutResponse.data.timer_id;
            const timerResponse = await api.get<TimerData>(`/timers/${timerId}`);
            setTimerData(timerResponse.data);
          } else {
            throw new Error("No workout exercises found");
          }
        } catch (error) {
          try {
            const currentDate = new Date().toISOString().split('T')[0];
            const wodResponse = await api.get(`/wods?date=${currentDate}`);
            const wodData = wodResponse.data;
            const timerResponse = await api.get(`/timers/${wodData[0].timer_id}`);
            setTimerData(timerResponse.data);

            const wodExercisesResponse = await api.get(`/wod_exercises?wod_id=${wodData[0].id}`);
            exercisesData = wodExercisesResponse.data;
          } catch (error) {
            console.error("Error fetching WOD exercises for the current date:", error);
          }
        }

        setWorkoutExercisesData(exercisesData);

        // Fetch exercise names only after workoutExercisesData is set
        if (exercisesData.length > 0) {

          const exerciseIds = exercisesData.map(exercise => exercise.exercise_id);
          const exercisesResponse = await api.get(`/exercises?ids=${exerciseIds.join(',')}`);
          const exercisesMap = exercisesResponse.data.reduce((acc: Record<number, string>, exercise: { id: number; name: string }) => {
            acc[exercise.id] = exercise.name;
            return acc;
          }, {});

          // Merge exercise names into exercisesData
          const exercisesWithNames = exercisesData.map(exercise => ({
            ...exercise,
            name: exercisesMap[exercise.exercise_id] || 'Unknown Exercise'
          }));

          setWorkoutExercisesData(exercisesWithNames);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (workoutId) {
      fetchData();
    }
  }, [workoutId]); // Run only when workoutId changes

  // Sort workoutExercisesData when it is updated
  const prevWorkoutExercisesDataRef = useRef<ExerciseData[] | undefined>(undefined);

  useEffect(() => {
    if (
      workoutExercisesData &&
      workoutExercisesData !== prevWorkoutExercisesDataRef.current
    ) {
      // Sort first by set_type (ascending), then by exercise_number (ascending)
      const sortedExercises = [...workoutExercisesData].sort((a, b) => {
        if (a.set_type !== b.set_type) {
          return a.set_type.localeCompare(b.set_type); // Compare set types
        } else {
          return a.exercise_number - b.exercise_number; // Compare exercise numbers
        }
      });

      // Only update state if sorted data is different
      if (
        JSON.stringify(sortedExercises) !== JSON.stringify(workoutExercisesData)
      ) {
        setWorkoutExercisesData(sortedExercises);
      }

      prevWorkoutExercisesDataRef.current = workoutExercisesData;
    }
  }, [workoutExercisesData]);

  const formattedTime = workoutData
    ? moment
      .tz(workoutData.start_datetime, "UTC")
      .tz(moment.tz.guess()) // Convert to user's timezone
      .format("HH:mm") // Customize the format as needed
    : "";

  // CODE TO DISPLAY THE TIMER
  useEffect(() => {
    let intervalId: NodeJS.Timeout; // Specify the type as number

    if (!timerData) {
      return;
    }

    if (
      isRunning &&
      timerData &&
      currentIntervalIndex < timerData.intervals.length
    ) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(intervalId);
            if (currentIntervalIndex < timerData.intervals.length - 1) {
              const newIntervalIndex = currentIntervalIndex + 1;
              const newTimeLeft =
                timerData.intervals[newIntervalIndex].duration;

              setCurrentIntervalIndex(newIntervalIndex);
              setTimeLeft(newTimeLeft);
            } else {
              clearInterval(intervalId);
              setIsRunning(false);

              // Fetch milestones and personal bests AFTER the workout ends
              const fetchData = async () => {
                try {
                  const milestonesResponse = await api.get(
                    `/workouts/${workoutId}/milestones`
                  );
                  setMilestones(milestonesResponse.data);

                } catch (error) {
                  console.error("Error fetching data:", error);
                  // Handle errors (e.g., display an error message to the user)
                } finally {
                  sendWorkoutAttendance();
                  setDisplayState("firstTimers");
                }
              };

              fetchData();

              return 0;
            }
          }
          return 0; // Ensure a number is always returned
        });
      }, 1000); // Update every 1000ms (1 second)
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning, currentIntervalIndex, timerData, workoutId]);

  // WebSocket Connection
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = process.env.NODE_ENV === 'production'
        ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
        : "ws://localhost:5000";

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log("WorkoutPlayer connected to WebSocket server");
        newWs.send(JSON.stringify({ type: "join", locationId }));
      };

      newWs.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (
          (data.type === "timerUpdate" || data.type === "loadTimer") &&
          data.locationId === locationId
        ) {
          setWorkoutId(data.workoutId);
          setDisplayState("timer")
          setCurrentIntervalIndex(data.timerState.intervalIndex);
          setTimeLeft(data.timerState.timeLeft);
          setIsRunning(data.timerState.isRunning);
        } else if (data.type === "workoutComplete") {
          soundWorkoutComplete.play();
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        newWs.close();
      };

      setWs(newWs);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [workoutId]);

  const sendWorkoutAttendance = async () => {
    try {
      const response = await api.post(`/bsport/send_attendance/${workoutId}`);
      console.log("Attendance updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating attendance:", error);
    }
  };

  // useEffect for handling progress bar transitions
  useEffect(() => {
    let interval: NodeJS.Timeout;
    const totalItems =
      displayState === "firstTimers"
        ? milestones?.firstTimers?.length || 0
        : displayState === "milestones"
          ? Object.keys(milestones || {}).filter((key) => key !== "firstTimers")
            .length
          : displayState === "personalBests"
            ? personalBests?.length || 0
            : 0;
    const timePerItem = 5000; // 5 seconds per item

    if (totalItems > 0 || displayState === "firstTimers") {
      setProgressBarValue(0);
      interval = setInterval(() => {
        setProgressBarValue((prevValue) => {
          const newValue =
            prevValue + (100 / (totalItems * timePerItem)) * 1000;
          if (newValue >= 100) {
            clearInterval(interval);
            if (displayState === "firstTimers") {
              setDisplayState(
                hasMilestones || hasPersonalBests ? "milestones" : "thankYou"
              );
            } else if (displayState === "milestones") {
              setDisplayState(hasPersonalBests ? "personalBests" : "thankYou");
            } else {
              setDisplayState("thankYou");
            }
            return 0;
          }
          return newValue;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    displayState,
    milestones,
    personalBests,
    hasMilestones,
    hasPersonalBests,
  ]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  // Helper function to format reps
  const formatReps = (reps: number) => {
    switch (reps) {
      case 1:
        return "1";
      case 4:
        return "4";
      case 6:
        return "6";
      case 8:
        return "8";
      case 12:
        return "12";
      default:
        return reps; // If no match, display the original reps value
    }
  };

  const intervalType = timerData?.intervals?.[
    currentIntervalIndex
  ]?.intervalType
    ?.replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const displayText = timerData?.intervals?.[currentIntervalIndex]
    ?.exercise_number
    ? "Exercise"
    : intervalType?.includes("Change")
      ? "Long Break"
      : intervalType;

  const getStrokeColorForInterval = (intervalType: string) => {
    if (intervalType.includes("main") || intervalType.includes("challenge")) {
      return "var(--color-neon-blue)";
    } else if (
      intervalType.includes("break") ||
      intervalType.includes("switch") ||
      intervalType.includes("stretching") ||
      intervalType.includes("breathing") ||
      intervalType.includes("station")
    ) {
      return "var(--color-grey)";
    } else if (intervalType.includes("warmup")) {
      return "var(--color-yellow)";
    } else {
      return "var(--color-cream)";
    }
  };

  const currentIntervalType =
    timerData?.intervals?.[currentIntervalIndex]?.intervalType || "";
  const strokeColor = getStrokeColorForInterval(currentIntervalType);

  // Function to calculate the segments for the progress bar
  const calculateSegments = (intervals: { duration: number; intervalType: string }[]) => {
    if (!intervals) return [];
    const segments: { duration: number; intervalType: string }[] = [];
    let currentSegment: { duration: number; intervalType: string } | null = null;

    intervals.forEach((interval, index) => {
      const isMainSet = interval.intervalType.includes("main set");
      const isBreak = interval.intervalType.includes("break");

      if (isMainSet || interval.intervalType.includes("switch")) {
        if (!currentSegment) {
          currentSegment = { duration: 0, intervalType: "main set" };
        }
        currentSegment.duration += interval.duration;
      } else {
        if (currentSegment) {
          segments.push(currentSegment);
          currentSegment = null;
        }
        segments.push({ duration: interval.duration, intervalType: interval.intervalType });
      }

      if (isBreak && currentSegment) {
        segments.push(currentSegment);
        currentSegment = null;
      }

      if (index === intervals.length - 1 && currentSegment) {
        segments.push(currentSegment);
      }
    });
    return segments;
  };

  const segments = calculateSegments(timerData?.intervals || []);

  // Function to calculate the fill width for each segment
  const calculateFillWidth = (segments: { duration: number; intervalType: string }[], totalDurationPassed: number) => {
    let remainingDuration = totalDurationPassed;
    return segments.map(segment => {
      const fillWidth = Math.min(segment.duration, remainingDuration);
      remainingDuration -= fillWidth;
      return {
        ...segment,
        fillWidth: (fillWidth / segment.duration) * 100
      };
    });
  };

  const totalDurationPassed = (timerData?.intervals?.slice(0, currentIntervalIndex) ?? [])
    .reduce((acc, interval) => acc + (interval?.duration ?? 0), 0)
    + ((timerData?.intervals?.[currentIntervalIndex]?.duration ?? 0) - timeLeft);

  const filledSegments = calculateFillWidth(segments, totalDurationPassed);

  useEffect(() => {
    if (timerData && timerData.intervals.length > 0) {
      const currentInterval = timerData.intervals[currentIntervalIndex];

      // Play sound based on interval type and time left
      if (currentInterval.intervalType.includes("main") && timeLeft === 20) {
        sound20.play();
      } else if (["switch", "long break", "setup"].includes(currentInterval.intervalType) && timeLeft === 10) {
        sound10.play();
      } else if (timeLeft === currentInterval.duration) {
        if (currentInterval.intervalType.includes("main")) {
          soundGo.play();
        } else {
          switch (currentInterval.intervalType) {
            case "switch":
              soundSwitch.play();
              break;
            case "long break":
              soundLongBreak.play();
              break;
            case "warmup":
              soundWarmUp.play();
              break;
            case "setup":
              soundGetReady.play();
              break;
            case "challenge":
              soundGo.play();
              // Check if this is the last interval
              if (currentIntervalIndex === timerData.intervals.length - 1) {
                soundWorkoutComplete.play();
              }
              break;
            default:
              break;
          }
        }
      }

      // Play Workout Start sound one second after the first interval starts
      if (currentIntervalIndex === 0 && timeLeft === currentInterval.duration - 1) {
        soundWorkoutStart.play();
      }

      // Play countdown sounds
      if (timeLeft <= 3 && timeLeft > 0) {
        switch (timeLeft) {
          case 3:
            sound3.play();
            break;
          case 2:
            sound2.play();
            break;
          case 1:
            sound1.play();
            break;
          default:
            break;
        }
      }
    }
  }, [timeLeft, currentIntervalIndex, timerData]);

  return (
    <div
      className="workout-player-container"
      style={{
        background: displayState === "timer" ? "var(--color-light-blue)" : "var(--color-cream)",
      }}
    >
      <svg
        className="lifted-logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 249 44"
        fill="currentColor" // This will inherit the color from CSS
      >
        <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
        <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
        <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
        <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
        <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
        <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
      </svg>
      {isLoading ? (
        <p>Loading workout...</p>
      ) : (
        <>
          {displayState === "timer" && (
            <>
              <div className="workout-center-content">
                <div className="left-column">
                  <div className="workout-timer-circle">
                    <svg width="1200" height="1200" viewBox="0 0 1200 1200">
                      {/* Background Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="590"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Content Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="482"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Progress Circle */}
                      <circle
                        className="workout-timer-circle-progress"
                        cx="600"
                        cy="600"
                        r="536"
                        fill="none"
                        stroke={strokeColor}
                        strokeWidth="98"
                        strokeLinecap="square"
                        style={{
                          strokeDasharray: strokeDashArray,
                          transform: "rotate(270deg)",
                          transformOrigin: "center",
                        }}
                      />
                    </svg>
                    <div className="workout-timer-text">
                      <div className="workout-description-text">
                        {displayText}
                      </div>
                      <div className="workout-time-left">
                        {formatTime(timeLeft)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-column">
                  <div className="workout-exercise-list">
                    {workoutExercisesData &&
                      workoutExercisesData.map((exercise, index) => (
                        <div className="workout-exercise-row" key={index}>
                          <div className="workout-exercise-number">
                            {String(exercise.exercise_number).padStart(2, "0")}
                          </div>
                          <div className="workout-exercise-name">
                            {exercise.name}
                          </div>
                          <div className="workout-exercise-reps">
                            {formatReps(exercise.reps)}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="progress-bar-container">
                {filledSegments.map((segment, index) => (
                  <div
                    key={index}
                    className="progress-bar-segment"
                    style={{
                      width: `${(segment.duration / (timerData?.total_duration ?? 0)) * 100}%`
                    }}
                  >
                    <div
                      className="progress-bar-fill"
                      style={{
                        width: `${segment.fillWidth}%`
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </>
          )}
          {displayState === "firstTimers" && ( // First timers display
            <div className="celebration-container">
              <h2 className="celebration-header">Workout complete!</h2>
              {(milestones?.firstTimers?.length ?? 0) > 0 && (
                <div className="celebration-grid">
                  <h3 className="celebration-subheader">
                    Props to our First Timers:
                  </h3>
                  <div className="celebration-grid">
                    {milestones?.firstTimers.map((customer) => (
                      <div
                        className="celebration-card"
                        key={customer.customer_id}
                      >
                        {customer.first_name} {customer.last_name.slice(0, 2)}.
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {displayState === "milestones" &&
            Object.keys(milestones || {}).filter((key) => key !== "firstTimers")
              .length > 0 && ( // Milestones display
              <div className="celebration-container">
                <h2 className="celebration-header">Milestones</h2>
                <div className="celebration-grid">
                  {Object.keys(milestones || {})
                    .filter((key) => key !== "firstTimers")
                    .map((key) => (
                      <div className="celebration-card" key={key}>
                        <div className="milestone-hexagon" style={{ backgroundColor: (milestones?.[key]?.[0]?.total_workouts ?? 0) > 500 ? "var(--color-neon-blue)" : (milestones?.[key]?.[0]?.total_workouts ?? 0) > 100 ? "var(--color-yellow)" : "var(--color-light-blue)" }}>
                          <div
                            className="milestone-number"
                            style={{
                              fontSize:
                                (milestones?.[key]?.[0]?.total_workouts ?? 0) < 100 ? "120px" : "96px",
                              color: (milestones?.[key]?.[0]?.total_workouts ?? 0) > 500 ? "var(--color-off-white)" : "inherit",
                            }}
                          >
                            {milestones?.[key]?.[0]?.total_workouts ?? 0}
                          </div>
                        </div>
                        {milestones?.[key]?.map((customer) => (
                          <div key={customer.customer_id}>
                            {customer.first_name} {customer.last_name.slice(0, 2)}.
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
          {displayState === "personalBests" &&
            (personalBests?.length ?? 0) > 0 && ( // Personal bests display
              <div className="celebration-container">
                <h2 className="celebration-header">Personal Bests</h2>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Improvement Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {personalBests?.map((pb, index) => (
                      <tr key={pb.customer_id}>
                        <td
                          style={{
                            color:
                              index < 3
                                ? index === 0
                                  ? "gold"
                                  : index === 1
                                    ? "silver"
                                    : "bronze"
                                : "white",
                          }}
                        >
                          {index + 1}
                          {index === 0
                            ? "st"
                            : index === 1
                              ? "nd"
                              : index === 2
                                ? "rd"
                                : "th"}
                        </td>
                        <td>
                          {pb.first_name} {pb.last_name}
                        </td>
                        <td>{pb.improvement_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          {displayState === "thankYou" && ( // Thank you display
            <div className="celebration-container">
              <h2 className="celebration-subheader" style={{ color: 'var(--color-neon-blue)' }}>Thank you!</h2>
              <h3 className="celebration-header" style={{ color: 'var(--color-dark-blue)' }}>See you <span style={{ fontFamily: 'PPEditorialNew' }}>soon</span></h3>
            </div>
          )}
          {["firstTimers", "milestones", "personalBests"].includes(
            displayState
          ) && (
              <div className="custom-progress-bar-container">
                <progress
                  value={progressBarValue}
                  max="100"
                  className="custom-progress-bar"
                ></progress>
              </div>
            )}
          {["timer", "thankYou"].includes(displayState) && (
            <div className="workout-footer">
              <p>Coach <span style={{ fontFamily: 'PPEditorialNew' }}>{workoutData?.coach_first_name}</span></p>
              <p style={{ fontSize: "48px" }}>
                {timerData?.name} at {formattedTime}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default WorkoutPlayer;