const weightIncrements = {
  "Leg Press": [4.5, 11, 18, 25, 32, 39, 45, 52, 59, 66, 73, 79, 86, 93, 100, 107, 113, 120, 127, 134, 141, 147, 154, 161, 168, 175],
  "Cable": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 95, 100, 105, 110, 115],
  "Dumbbell": [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25, 27.5, 30],
  "Barbell": [11, 20, 29, 38, 47, 56, 65, 74, 83],
  "Smith Machine": [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 95, 100, 105]
};

type EquipmentType = keyof typeof weightIncrements;

export const getNextWeight = (equipment: EquipmentType, currentWeight: number, change: number): number => {
  const increments = weightIncrements[equipment];
  if (!increments) return currentWeight;

  const currentIndex = increments.indexOf(currentWeight);
  if (currentIndex === -1) return currentWeight;

  const newIndex = currentIndex + change;
  const clampedIndex = Math.max(0, Math.min(newIndex, increments.length - 1));
  return increments[clampedIndex];
};

export const calculateWeightDifference = (equipment: EquipmentType, currentWeight: number, change: number): number | null => {
  const increments = weightIncrements[equipment];
  if (!increments) return null;

  const currentIndex = increments.indexOf(currentWeight);
  if (currentIndex === -1) return null;

  const newIndex = currentIndex + change;
  if (newIndex < 0 || newIndex >= increments.length) return null;

  return increments[newIndex] - currentWeight;
}; 