import React, { useState, useRef, ChangeEvent } from "react";
import api from './axiosConfig';
import { useNavigate } from "react-router-dom";
import "./AdminForm.css"; // Your CSS file
import "./AdminList.css"; // Your CSS file
import Layout from "./Layout";

// Define the Exercise interface
interface Exercise {
  id?: string;
  name?: string;
  type?: string;
  body_part?: string;
  muscle_group?: string;
  equipment?: string;
  supporting_equipment?: string;
  main_muscle?: string;
  video_url?: string;
  [key: string]: any; // Allow additional fields
}

function ExerciseCreateEdit() {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [csvData, setCsvData] = useState<Exercise[] | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Allowed values for categorical fields
  const allowedExerciseTypes = [
    "Strength",
    "Cardio",
    "Warm Up",
    "Stretching",
    "Breathing",
  ];
  const allowedBodyParts = ["Upper Body", "Lower Body", "Core", "Full Body"];
  const allowedEquipment = [
    "Cable",
    "Barbell",
    "Dumbbell",
    "Leg Press",
    "Smith Machine",
    "None",
  ];
  const allowedSupportingEquipment = ["Mat", "Stepper", "Bench", "None"];
  const allowedMuscleGroups = [
    "Chest",
    "Upper Back",
    "Lower Back",
    "Core",
    "Legs",
    "Shoulders",
  ];
  const allowedMainMuscles = [
    "None",
    "Biceps",
    "Triceps",
    "Upper Back",
    "Lower Back",
    "Chest",
    "Core",
    "Legs",
    "Glute Max",
    "Shoulders",
    "Multiple",
    "Hip Flexors",
    "Quads",
    "Glute Med",
    "Mid Back",
    "Forearms",
    "Calves",
    "Rectus Abs",
    "Transverse Abs",
    "Obliques",
    "Lats",
    "Traps",
    "Erector Spinae",
    "Adductor",
    "Abductor",
    "Hamstrings",
  ];
  const intensityFields = [
    "legs",
    "adductor",
    "abductor",
    "hamstrings",
    "glute_max",
    "glute_med",
    "quads",
    "hip_flexors",
    "calves",
    "forearms",
    "biceps",
    "triceps",
    "upper_back",
    "mid_back",
    "lower_back",
    "traps",
    "erector_spinae",
    "lats",
    "chest",
    "shoulders",
    "core",
    "transverse_abs",
    "rectus_abs",
    "obliques",
    "complexity",
  ];
  const requiredFields = [
    "name",
    "type",
    "body_part",
    "muscle_group",
    "equipment",
    "supporting_equipment",
    "main_muscle",
    "id",
  ];

  const defaultWeightFields = [
    "default_weight_1rm",
    "default_weight_4rm",
    "default_weight_6rm",
    "default_weight_8rm",
    "default_weight_12rm",
  ];

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      setCsvData(null); // Clear previous data
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const contents = e.target?.result as string;
      const rows = contents.split("\n").map((row) => row.split(","));
      const headers = rows[0].map((header) =>
        header.replace(/\r/g, "").replace(/"/g, "")
      );

      const data = rows
        .slice(1)
        .map((row) => {
          const obj: Exercise = {};
          headers.forEach((header, i) => {
            let value = row[i];
            if (value) {
              value = value.replace(/\r/g, "");

              // Remove surrounding double quotes (if present)
              if (value.startsWith('"') && value.endsWith('"')) {
                value = value.slice(1, -1); // Remove first and last character (the quotes)
              }
            }

            if (intensityFields.includes(header)) {
              obj[header] = parseInt(value, 10) || 0;
            } else if (defaultWeightFields.includes(header)) {
              const parsedValue = parseFloat(value); // Use parseFloat for floats
              obj[header] = isNaN(parsedValue) ? null : parsedValue;
            } else {
              obj[header] = value;
            }
          });
          return obj;
        })
        .filter(Boolean); // Remove empty objects (in case of blank lines in the CSV)

      setCsvData(data);

      // Validate after parsing the CSV data and display errors/preview if needed
      const validationErrors = validateCsvData(headers, data);
      if (validationErrors.length > 0) {
        setErrors(validationErrors);
      } else {
        console.log("Preview (Row 2):", data[0]);
      }
    };
    reader.readAsText(file);
  };

  const validateCsvData = (headers: string[], data: Exercise[]) => {
    const errors: string[] = [];

    // Check for missing required fields
    const missingFields = requiredFields.filter(
      (field) => !headers.includes(field)
    );
    if (missingFields.length > 0) {
      errors.push(`Missing required fields: ${missingFields.join(", ")}`);
    }

    // Check for invalid values for categorical fields
    for (let i = 0; i < data.length; i++) {
      const exercise = data[i];
      if (!exercise.name || exercise.name.trim() === "") {
        errors.push(`Row ${i + 2}: Name cannot be empty`);
      }
      if (exercise.type && !allowedExerciseTypes.includes(exercise.type)) {
        errors.push(`Row ${i + 2}: Invalid exercise type '${exercise.type}'`);
      }
      if (
        exercise.body_part &&
        !allowedBodyParts.includes(exercise.body_part)
      ) {
        errors.push(`Row ${i + 2}: Invalid body part '${exercise.body_part}'`);
      }
      if (
        exercise.muscle_group &&
        !allowedMuscleGroups.includes(exercise.muscle_group)
      ) {
        errors.push(
          `Row ${i + 2}: Invalid muscle group '${exercise.muscle_group}'`
        );
      }
      if (
        exercise.equipment &&
        !allowedEquipment.includes(exercise.equipment)
      ) {
        errors.push(`Row ${i + 2}: Invalid equipment '${exercise.equipment}'`);
      }
      if (
        exercise.supporting_equipment &&
        !allowedSupportingEquipment.includes(exercise.supporting_equipment)
      ) {
        errors.push(
          `Row ${i + 2}: Invalid supporting equipment '${
            exercise.supporting_equipment
          }'`
        );
      }
      if (
        exercise.main_muscle &&
        !allowedMainMuscles.includes(exercise.main_muscle)
      ) {
        errors.push(
          `Row ${i + 2}: Invalid main muscle '${exercise.main_muscle}'`
        );
      }

      // Validate intensity fields (1-5 or null)
      for (const field of intensityFields) {
        const value = exercise[field];
        if (
          value !== undefined &&
          value !== null &&
          (typeof value !== "number" || value < 0 || value > 5)
        ) {
          errors.push(
            `Row ${
              i + 2
            }: Invalid value for ${field}. Must be an integer between 0 and 5 or null.`
          );
        }
      }

      // Validate video_url field
      if (exercise.video_url && !isValidUrl(exercise.video_url)) {
        errors.push(`Row ${i + 2}: Invalid URL '${exercise.video_url}'`);
      }
    }

    return errors;
  };

  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  const handleConfirm = async () => {
    // Process the CSV data here (create or update exercises)

    // Example logic to create/update exercises
    try {
      if (csvData) {
        for (const exercise of csvData) {
          console.log(exercise);
          if (exercise.id) {
            // Update existing exercise
            await api.put(`/exercises/${exercise.id}`, exercise);
          } else {
            // Create new exercise
            const { id, ...exerciseWithoutId } = exercise;
            console.log(exerciseWithoutId);
            await api.post("/exercises", exerciseWithoutId); // Send data without the 'id' field
          }
        }
      }

      // Redirect or show success message
      navigate("/exercises"); // Navigate back to the exercise list page
    } catch (error) {
      console.error("Error creating/updating exercises:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  const handleCancel = () => {
    setFile(null);
    setCsvData(null);
    setErrors([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Upload Exercise CSV</h2>
        {/* File Input (always visible) */}
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div>
          <button onClick={handleUpload} disabled={!file}>
            Upload
          </button>
        </div>

        {/* Error Display */}
        {errors.length > 0 && (
          <div className="error-list">
            <h3>Errors:</h3>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Preview (conditionally rendered) */}
        {csvData &&
          errors.length === 0 && ( // Only show preview if data is valid and there are no errors
            <div>
              <h3>Preview (Row 2):</h3>
              <pre>{JSON.stringify(csvData[0], null, 2)}</pre>
              <div className="horizontal-buttons">
                <button onClick={handleConfirm}>Confirm</button>
                <button onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          )}
      </div>
    </Layout>
  );
}

export default ExerciseCreateEdit;