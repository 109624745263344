import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import api from './axiosConfig';
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

interface AuthContextType {
  user: User | null;
  userRoles: string[];
  isLoading: boolean;
  isAuthenticating: boolean;
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>;
  login: (user: User) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  //States
  const [user, setUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const unsubscribeAuthStateChangedRef = useRef<(() => void) | null>(null);

  // Check Local Storage and Initiate Login (if user there)
  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      console.log("Found stored user: ", JSON.parse(storedUser));
      setIsAuthenticating(true);
    } else {
      setIsLoading(false); // No user in storage, no login needed
    }
  }, []); // Run only once on mount

  // Handle Firebase Auth State Changes and Subsequent Actions
  useEffect(() => {
    unsubscribeAuthStateChangedRef.current = onAuthStateChanged(
      getAuth(),
      (user) => {
        if (user) {
          console.log("Auth state changed to logged in:", user);
          login(user);
        } else {
          setUser(null);
          setUserRoles([]);
          setIsLoading(false);
          setIsAuthenticating(false);
        }
      }
    );

    return () => {
      if (unsubscribeAuthStateChangedRef.current)
        unsubscribeAuthStateChangedRef.current();
    };
  }, []);

  // Login Function (with Role Fetching and Redirection)
  const login = async (user: User) => {
    setUser(user);
    try {
      const rolesResponse = await api.get<string[]>(
        `/users/firebase-uid/${user.uid}/roles`
      );
      setUserRoles(rolesResponse.data);
      localStorage.setItem("currentUser", JSON.stringify(user));
      // Redirect logic
      const from = location.state?.from?.pathname || "/";

      if (from === "/login" || location.pathname === "/login") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    } finally {
      console.log("Logged in user:", user);
      setIsAuthenticating(false);
    }
  };

  // Add Axios Interceptor (after Authentication)
  useEffect(() => {
    let authInterceptor: number | null = null;

    const addAuthInterceptor = async () => {
      const currentUser = getAuth().currentUser; // Get the current user
      if (currentUser) {
        try {
          const idToken = await currentUser.getIdToken(true);

          authInterceptor = api.interceptors.request.use(
            async (config) => {
              const decodedToken: any = jwtDecode(idToken);
              const currentTime = Date.now() / 1000;

              // Check if token is about to expire in the next 5 minutes
              if (decodedToken.exp - currentTime < 300) {
                const newToken = await refreshToken();
                if (newToken) {
                  config.headers.Authorization = `Bearer ${newToken}`;
                }
              } else {
                config.headers.Authorization = `Bearer ${idToken}`;
              }
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        } catch (error) {
          console.error("Error getting ID token or adding interceptor:", error);
        }
      }
    };

    const removeAuthInterceptor = () => {
      if (authInterceptor !== null) {
        api.interceptors.request.eject(authInterceptor);
      }
    };

    // Directly await the interceptor addition
    if (user && !isAuthenticating) {
      console.log("Adding interceptor");
      addAuthInterceptor()
        .then(() => {
          console.log("Interceptor added successfully");
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error adding interceptor:", error);
        });
    }

    return () => removeAuthInterceptor();
  }, [user, isAuthenticating]); // Dependencies are still correct

  // Logout Function
  const logout = () => {
    signOut(getAuth())
      .then(() => {
        setUser(null);
        setUserRoles([]);
        localStorage.removeItem("currentUser"); // Remove user from local storage
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  // Method to refresh the token
  const refreshToken = async (): Promise<string | null> => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      try {
        const idToken = await currentUser.getIdToken(true); // Force refresh
        return idToken;
      } catch (error) {
        console.error("Error refreshing token:", error);
        return null;
      }
    }
    return null;
  };

  // Context Value
  const value = {
    user,
    userRoles,
    isLoading,
    isAuthenticating,
    setIsAuthenticating,
    login,
    logout,
    refreshToken, // Expose the refreshToken method
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
