import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import { useParams, Link } from "react-router-dom";
import "./AdminForm.css";
import { FaEdit } from "react-icons/fa";
import Layout from "./Layout";

interface Customer {
  id: string;
  first_name: string;
  last_name: string;
  sex: string;
  birthdate: string;
  created_at: string;
  user_id: string;
  bsport_id: number;
  email: string;
  height: number;
  weight: number;
  total_workouts: number;
}

const CustomerDetails: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const customerResponse = await api.get(
          `/customers/${customerId}`
        );
        const customer = customerResponse.data;
        setCustomer(customer);

        const rolesResponse = await api.get<string[]>(
          `/users/${customer.user_id}/roles`
        );
        setRoles(rolesResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch customer data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerId]);

  if (isLoading) {
    return (
      <div className="admin-form-container">
        <p>Loading customer details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-form-container">
        <p className="error-message">Error: {error}</p>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="admin-form-container">
        <p className="error-message">Customer not found.</p>
      </div>
    );
  }

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Customer Details</h2>
        <table className="info-table">
          <tbody>
            <tr>
              <td>
                <label>First Name:</label>
              </td>
              <td>{customer.first_name}</td>
            </tr>
            <tr>
              <td>
                <label>Last Name:</label>
              </td>
              <td>{customer.last_name}</td>
            </tr>
            <tr>
              <td>
                <label>Sex:</label>
              </td>
              <td>{customer.sex}</td>
            </tr>
            <tr>
              <td>
                <label>Birthdate:</label>
              </td>
              <td>{customer.birthdate}</td>
            </tr>
            <tr>
              <td>
                <label>Sign Up:</label>
              </td>
              <td>{customer.created_at}</td>
            </tr>
            <tr>
              <td>
                <label>Roles:</label>
              </td>
              <td>
                {roles.length === 0 ? (
                  <p>No roles assigned</p>
                ) : (
                  <p>{roles.join(", ")}</p>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label>BSport ID:</label>
              </td>
              <td>{customer.bsport_id}</td>
            </tr>
            <tr>
              <td>
                <label>Email:</label>
              </td>
              <td>{customer.email}</td>
            </tr>
            <tr>
              <td>
                <label>Height:</label>
              </td>
              <td>{customer.height}</td>
            </tr>
            <tr>
              <td>
                <label>Weight:</label>
              </td>
              <td>{customer.weight}</td>
            </tr>
            <tr>
              <td>
                <label>Total Workouts:</label>
              </td>
              <td>{customer.total_workouts}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <Link to={`/customer/${customer.id}/edit`}>
            <button>
              <FaEdit title="Edit Customer" />
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDetails;
