import React, { useState, ChangeEvent, FormEvent } from 'react';
import api from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import "./AdminForm.css"; 
import Layout from "./Layout";

// Define the Location interface
interface Location {
  name: string;
  latitude: string;
  longitude: string;
  google_maps_link: string;
  address: string;
  zip_code: string;
  city: string;
  max_spots: number;
  bsport_id: string;
  floorplan_url: string;
}

function LocationCreate() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<Location>({
      name: '',
      latitude: '',
      longitude: '',
      google_maps_link: '',
      address: '',
      zip_code: '',
      city: '',
      max_spots: 36,
      bsport_id: '',
      floorplan_url: ''
    });
  
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
  
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setLocation(prevLocation => ({ ...prevLocation, [name]: value }));
    };
  
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);
  
      try {
        await api.post('/locations', location);
        navigate('/locations');
      } catch (err) {
        console.error('Error creating location:', err);
        setError('Failed to create location');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <Layout>
      <div className="admin-form-container">
        <h2>Create New Location</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td><label htmlFor="name">Name:</label></td>
                  <td><input type="text" id="name" name="name" value={location.name} onChange={handleChange} required /></td>
                </tr>
                <tr>
                  <td><label htmlFor="latitude">Latitude:</label></td>
                  <td><input type="number" id="latitude" name="latitude" step="0.000001" value={location.latitude} onChange={handleChange} required /></td>
                </tr>
                <tr>
                  <td><label htmlFor="longitude">Longitude:</label></td>
                  <td><input type="number" id="longitude" name="longitude" step="0.000001" value={location.longitude} onChange={handleChange} required /></td>
                </tr>
                <tr>
                  <td><label htmlFor="google_maps_link">Google Maps Link:</label></td>
                  <td><input type="text" id="google_maps_link" name="google_maps_link" value={location.google_maps_link} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td><label htmlFor="address">Address:</label></td>
                  <td><input type="text" id="address" name="address" value={location.address} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td><label htmlFor="zip_code">Zip Code:</label></td>
                  <td><input type="text" id="zip_code" name="zip_code" value={location.zip_code} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td><label htmlFor="city">City:</label></td>
                  <td><input type="text" id="city" name="city" value={location.city} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td><label htmlFor="max_spots">Max Spots:</label></td>
                  <td><input type="number" id="max_spots" name="max_spots" value={location.max_spots} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td><label htmlFor="bsport_id">Bsport ID:</label></td>
                  <td><input type="text" id="bsport_id" name="bsport_id" value={location.bsport_id} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="floorplan_url">Floorplan URL:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="floorplan_url"
                      name="floorplan_url"
                      value={location.floorplan_url}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" disabled={isLoading}>Create Location</button>
          </form>
        )}
      </div>
      </Layout>
    );
  }
  
  export default LocationCreate;
